<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('SupportInitiationStatuses')"
    @cancel="onCancel('SupportInitiationStatuses')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'SupportGisTypeCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'SupportInitiationStatuses',
      pageHeader: 'Создание статуса',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Статусы',
          route: { name: 'SupportInitiationStatuses' },
        },
        {
          text: 'Создание статуса',
        },
      ],
      initialForm: {
        name: null,
      },
      form: null,
    };
  },

  computed: {
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
          ],
        },
      ];
    },
  },

  methods: {
    ...mapActions(['createItem']),
  },
};
</script>
